import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useOrderFromUrl from '../../hooks/orderFromUrl';
import styles from './FormOverview.module.scss';

function FormOverview({ submitted }) {
  const [order, url] = useOrderFromUrl();
  const inputNode = useRef(null);
  const handleCopy = () => {
    inputNode.current.select();
    inputNode.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  if (!order) {
    return null;
  }

  const date = new Date(order.date);
  const dateString = date.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return (
    <div className={`section ${styles.section}`}>
      <div className="container">
        {submitted && (
          <div className={styles.linkSection}>
            <label className={styles.inputLabel} htmlFor="link">Kopieren Sie diesen Link um Ihre Anfrage zu speichern oder zu verschicken:</label>
            <div className={styles.copyForm}>
              <input readOnly id="link" value={url} ref={inputNode} className={styles.copyInput} />
              <button aria-label="Link kopieren" className={`button button--dark ${styles.button}`} onClick={handleCopy}>Link kopieren</button>
            </div>
          </div>
        )}

        <h4>Veranstaltungsdaten</h4>
        <div className={styles.eventData}>
          <div className={styles.item}>
            <span className={styles.label}>Wann:</span>
            <span className={styles.value}>{dateString}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>Anzahl der Personen:</span>
            <span className={styles.value}>{order.persons}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>Brauchen Sie auch Besteck und Geschirr:</span>
            <span className={styles.value}>{order.withDishes}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.label}>Wie kommt das Essen zu Ihnen:</span>
            <span className={styles.value}>{order.delivery}</span>
          </div>
        </div>

        {order.meatDishes.length > 0 && (
          <div>
            <h4>Fleischgerichte</h4>
            <ul>
              {order.meatDishes.map((dish) => (
                <li key={dish}>{dish}</li>
              ))}
            </ul>
          </div>
        )}

        {order.barbecue.length > 0 && (
          <div>
            <h4>Barbecue</h4>
            <ul>
              {order.barbecue.map((dish) => (
                <li key={dish}>{dish}</li>
              ))}
            </ul>
          </div>
        )}

        {order.sideDishes.length > 0 && (
          <div>
            <h4>Beilagen</h4>
            <ul>
              {order.sideDishes.map((dish) => (
                <li key={dish}>{dish}</li>
              ))}
            </ul>
          </div>
        )}

        {order.salads.length > 0 && (
          <div>
            <h4>Salate</h4>
            <ul>
              {order.salads.map((dish) => (
                <li key={dish}>{dish}</li>
              ))}
            </ul>
          </div>
        )}

        {order.fingerFood.length > 0 && (
          <div>
            <h4>Fingerfood und kalte Platten</h4>
            <ul>
              {order.fingerFood.map((dish) => (
                <li key={dish}>{dish}</li>
              ))}
            </ul>
          </div>
        )}

        {order.soups.length > 0 && (
          <div>
            <h4>Suppen</h4>
            <ul>
              {order.soups.map((dish) => (
                <li key={dish}>{dish}</li>
              ))}
            </ul>
          </div>
        )}

        {order.desserts.length > 0 && (
          <div>
            <h4>Nachtische</h4>
            <ul>
              {order.desserts.map((dish) => (
                <li key={dish}>{dish}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

FormOverview.propTypes = {
  submitted: PropTypes.bool,
};

FormOverview.defaultProps = {
  submitted: false,
};

export default FormOverview;
