import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import SEO from '../container/Seo/Seo';
import Layout from '../presentational/Layout/Layout';
import Banner from '../presentational/Banner/Banner';
import FormOverview from '../presentational/FormOverview/FormOverview';

function Overview({ location }) {
  return (
    <Layout pathname={location.pathname}>
      <SEO title="Übersicht Ihrer Anfrage" />
      <Banner />

      <section className="section">
        <div className="container">
          <h1>Übersicht Ihrer Anfrage</h1>
        </div>
      </section>

      <FormOverview />
    </Layout>
  );
}

Overview.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Overview;
