import { useState, useEffect } from 'react';

function useOrderFromUrl() {
  const [order, setOrder] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const { href } = window.location;

    if (href.includes('order=')) {
      try {
        const stringFromUrl = href.split('order=')[1];
        const parsedData = JSON.parse(decodeURIComponent(stringFromUrl));
        setUrl(`${window.location.origin}/menue-zusammenfassung?order=${stringFromUrl}`);
        setOrder(parsedData);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return [order, url];
}

export default useOrderFromUrl;
